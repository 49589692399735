//@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,500,700|Droid+Serif:400,400italic');

@font-face {
  font-family: "DroidSerif";
  src: url("../fonts/droid/DroidSerif.eot"); /* IE9 Compat Modes */
  src: url("../fonts/droid/DroidSerif.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/droid/DroidSerif.otf") format("opentype"), /* Open Type Font */
    url("../fonts/droid/DroidSerif.svg") format("svg"), /* Legacy iOS */
    url("../fonts/droid/DroidSerif.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/droid/DroidSerif.woff") format("woff"), /* Modern Browsers */
    url("../fonts/droid/DroidSerif.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/ubuntu/Ubuntu-Regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/ubuntu/Ubuntu-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/ubuntu/Ubuntu-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/ubuntu/Ubuntu-Regular.svg") format("svg"), /* Legacy iOS */
    url("../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/ubuntu/Ubuntu-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/ubuntu/Ubuntu-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}