#sidebar {
  @include breakpoint(medium) {
    position: fixed;
    left: 0;
    top: 0;
  }
  padding-right: 20px;
}
#greenbox {
  background-image: url('../img/andymey_greenbox_stripes.png');
  background-repeat: no-repeat;
  background-position: top left;
  border-bottom-right-radius: 40px;
  width: 100%;
  @include breakpoint(medium) {
    width: 75%;
  }
  @include breakpoint(large) {
    width: 100%;
  }
}
#databox {
  padding-top: 12px;
  table {
    td, th {
      vertical-align: top;
      line-height: 17px;
    }
    td {
      text-align: right;
      width: 75%;
      font-style: italic;
    }
    th {
      text-align: left;
      font-family: $header-font-family;
    }
    @include breakpoint(medium) {
      max-width: 374px;
    }
    @include breakpoint(large) {
      max-width: 100%;
    }
  }
}
#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-right: 20px;

  .menu {
    margin-right: 0;
    
    li {
      a {
        margin: 0 1rem;
        padding: 1rem;
      }
    }
  }

  @include breakpoint(medium) {
    max-width: 369px;
  }
  @include breakpoint(large) {
    max-width: 512px;
  }
}
#content {
  margin-left: 0;
  padding: 0 20px 40px 20px;
  @include breakpoint(medium) {
    padding-left: 400px;
  }
  @include breakpoint(large) {
    padding-left: 540px;
  }
}
h1 {
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 22.5px;

  @include breakpoint(medium) {
    margin-top: 40px;
    line-height: 30px;
  }
}
h2 {
  font-family: $body-font-family;
  font-style: italic;
}
h3 {
  color: $primary-color;
}

.h-light1 {
  color: #C4CA53;
  font-weight: bold;
  font-size: 0.93em;
  line-height: 0.9em;
}
.h-light2 {
  color: #B0B91A;
  font-weight: bold;
  font-size: 1em;
  line-height: 0.7em;
}
.h-gray1 {
  color: #A8C1B7;
  font-weight: 500;
  font-size: 0.55em;
  line-height: 0.7em;
}
.h-gray2 {
  color: #789F8F;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 0.7em;
}
.h-dark1 {
  color: #00492C;
  font-weight: 300;
  padding-left: 0.5em;
  font-size: 1.3em;
  line-height: 0.5em;
}
.h-dark2 {
  color: #00492C;
  font-weight: 300;
  font-size: 1.3em;
  line-height: 0.7em;
}
.menu {
  background-image: url('../img/andymey_greenbox_stripes.png');
  background-repeat: no-repeat;
  background-position: top left;
  border-top-right-radius: 40px;
  margin-right: 30px;
  font-family: $header-font-family;
  
  a {
    color: $white;
    &:hover, &:active {
      color: $secondary-color;
    }
  }
}


#CybotCookiebotDialog, #CookiebotWidget {
    color: $body-font-color !important;
    
    a, div {
        font-family: $header-font-family !important;
    }
    a {
        color: $primary-color !important;
    }
}

#CybotCookiebotDialog {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive,
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
        border-color: $primary-color !important;
    }
    #CybotCookiebotDialogHeader {
        display: none;
    }
    #CybotCookiebotDialogBodyContentTitle {
        font-family: $body-font-family !important;
    }
    #CybotCookiebotDialogBodyContentControls {
        font-family: $header-font-family !important;
    }
    #CybotCookiebotDialogBodyButtonAccept {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
        &:hover {
            background-color: $secondary-color !important;
            border-color: $secondary-color !important;
        }
    }
    #CybotCookiebotDialogBodyButtonDecline {
        background-color: $medium-gray !important;
        border-color: $medium-gray !important;
        &:hover {
            background-color: $dark-gray !important;
            border-color: $dark-gray !important;
        }
    }
}

#CookiebotWidget {
    .CookiebotWidget-body {
        .CookiebotWidget-consents-list svg {
            fill: $primary-color !important;
        }
        
        button {
            color: $primary-color !important;
        }
    }
    
    #CookiebotWidget-btn-change {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
        &:hover {
            background-color: $secondary-color !important;
            border-color: $secondary-color !important;
        }
    }

    #CookiebotWidget-btn-withdraw {
        background-color: $medium-gray !important;
        border-color: $medium-gray !important;
        &:hover {
            background-color: $dark-gray !important;
            border-color: $dark-gray !important;
        }
    }
}